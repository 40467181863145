<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
    @click="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <div class="navbar-brand">
              <span class="ll-logo1">
                <b-img
                  :src="require('@/assets/images/logo/logo.png')"
                  alt="logo"
                />
              </span>
              <span class="ll-logo2">
                <b-img
                  :src="host>-1?kerryexpressLogo:appLogoImage"
                  alt="logo"
                />
              </span>
            </div>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      initMenu: [],
      host:window.location.host.indexOf('ordering.hk.kerryexpress.com'),
      // kerryexpress: require('@/assets/images/imgs/kerryexpress.svg'), ordering.hk.kerryexpress.com
    }
  },
  created() {
    this.permissions()
  },
  methods: {
    permissions() {
      const userinfo = JSON.parse(localStorage.getItem('userInfo'))
      if (userinfo !== null) {
        // this.navMenuItems = userinfo.permissions
        const menuPermiss = userinfo.permissions
        let arr1 = []
        let headTitle1 = { header: 'Amazon Order' }
        menuPermiss.find(item=>{
          if (item.route=='amazon-job' || item.route=='pre-alert' || item.route=='invoice-list' || item.route=='charge-table') {
            arr1.push(item)
          }
        })
        if (arr1.length !== 0){
          arr1.unshift(headTitle1)
        }

        let arr2 = []
        let headTitle2 = { header: 'Delivery Order' }
        menuPermiss.find(item=>{
          if (item.route=='orderList' || item.route=='orderList2' || item.route=='pickup-request' || item.route=='regular-pickup' || item.route=='upload-order' || item.route=='batch-update' || item.route=='cod-fod-list' || item.route=='PY-Waybill') {
            arr2.push(item)
          }
        })
        if (arr2.length !== 0){
          arr2.unshift(headTitle2)
        }

        // let arr3 = []
        // let headTitle3 = { header: 'Fulfillment' }
        // menuPermiss.find(item=>{
        //   if (item.route=='inbound' || item.route=='outbound' || item.route=='skuMaster' || item.route=='salesOrder'
        //   || item.route=='asn' || item.route=='inventory') {
        //     arr3.push(item)
        //   }
        // })
        // if (arr3.length !== 0){
        //   arr3.unshift(headTitle3)
        // }

        let arr4 = []
        let headTitle4 = { header: 'CUS Operation' }
        menuPermiss.find(item=>{
          if (item.route=='operation' || item.route=='cus-order-update') {
            arr4.push(item)
          }
        })
        if (arr4.length !== 0){
          arr4.unshift(headTitle4)
        }

        let arr5 = []
        let headTitle5 = { header: 'Report' }
        menuPermiss.find(item=>{
          if (item.route=='inbound-report' || item.route=='batch-download' || item.route=='batch-download-signature' || item.route=='download-report' || item.route=='download-invoice' || item.route=='status-report') {
            arr5.push(item)
          }
        })
        if (arr5.length !== 0){
          arr5.unshift(headTitle5)
        }

        let arr6 = []
        let headTitle6 = { header: 'Business Profile' }
        menuPermiss.find(item=>{
          if (item.route=='merchant-label' || item.route=='customer-list2') {
            arr6.push(item)
          }
        })
        if (arr6.length !== 0){
          arr6.unshift(headTitle6)
        }

        let arr8 = []
        let headTitle8 = { header: 'LOG' }
        menuPermiss.find(item=>{
          if (item.route=='api-request-error-log' || item.route=='sf-one-stop-monitor' || item.route=='problem-order-call-log') {
            console.info('in--------')
            arr8.push(item)
          }
        })
        if (arr8.length !== 0){
          arr8.unshift(headTitle8)
        }

        let arr7 = []
        let headTitle7 = { header: 'Config Center' }
        menuPermiss.find(item=>{
          if (item.title=='Master Data' || item.title=='User' || item.title=='Configuration') {
            arr7.push(item)
          }
        })
        if (arr7.length !== 0){
          arr7.unshift(headTitle7)
        }
        this.navMenuItems = [...arr1, ...arr2,  ...arr4, ...arr5, ...arr6, ...arr8, ...arr7]
      } else {
        this.$router.push({ name: 'auth-login' })
      }
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage , kerryexpressLogo} = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      kerryexpressLogo,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
<style scoped>
[dir] .main-menu .navbar-header .navbar-brand{
  margin-left: unset;
}
.main-menu .navbar-header .navbar-brand .ll-logo2 img{
  max-width: unset !important;
  width: 100%;
  height: 33px;
}
.ll-logo1{
  /* display: none; */
  margin-right: 1rem;
}
</style>
